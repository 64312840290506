import React from "react";
import styled from "styled-components";

const StyledContainerWrapper = styled.section`
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
`;

const ContainerWrapper = ({ children, backgroundColor, ...props }) => {
  return (
    <StyledContainerWrapper backgroundColor={backgroundColor} {...props}>
      {children}
    </StyledContainerWrapper>
  );
};

export default ContainerWrapper;
