import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

import { PrevArrow, NextArrow } from "./Arrows";
import { GalleryArrowType } from "../../constants/enums";
import { Colors } from "../../constants/Colors";
import { BreakpointValues } from "../../constants/Breakpoints";

import "slick-carousel/slick/slick.css";
import "./styles.css";

const SliderContainer = styled.div`
  width: 100%;
  z-index: 10;
  position: relative;
`;

const CustomDots = styled.div`
  position: relative
  width: 6px;
  height: 6px;
  bottom: 0;
  border-radius: 4px;
  background-color: ${({ navColor }) =>
    navColor === GalleryArrowType.WHITE ? "white" : Colors.LOW_LIGHT_GRAY};
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

const ImageSlider = ({
  children,
  cardNum,
  mobileCardNum,
  tabletCardNum,
  lapTopCardNum,
  arrowPos,
  itemsLength,
  navColor,
  centerMode,
  renderTop,
  renderBottom,
  ...props
}) => {
  const [nextArrowActive, setNextArrowActive] = useState(true);
  const [prevArrowActive, setPrevArrowActive] = useState(false);

  const showHideArrows = (current, slidesToShow) => {
     if (current === 0) {
        setPrevArrowActive(false);
      } else {
        setPrevArrowActive(true);
      }
      if (current >= (itemsLength - slidesToShow) && current > 0) {
        setNextArrowActive(false);
      } else {
        setNextArrowActive(true);
      }
  }


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: cardNum,
    slidesToScroll: 1,
    nextArrow: <NextArrow nextArrowActive={nextArrowActive} arrowPos={arrowPos} navColor={navColor} />,
    prevArrow: <PrevArrow prevArrowActive={prevArrowActive} arrowPos={arrowPos} navColor={navColor} />,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => <CustomDots navColor={navColor} />,   
    afterChange: (current) => {
    showHideArrows(current, cardNum)
    },
    responsive: [
      {
        breakpoint: BreakpointValues.sm,
        settings: {
          slidesToShow: mobileCardNum ? mobileCardNum : 1,
          afterChange: (current) => {
          showHideArrows(current, mobileCardNum)
          },
        },
      },
      {
        breakpoint: BreakpointValues.md,
        settings: {
          slidesToShow: tabletCardNum ? tabletCardNum : 2,
          afterChange: (current) => {
          showHideArrows(current, tabletCardNum)
          },
        },
      },
      {
        breakpoint: BreakpointValues.lg,
        settings: {
          slidesToShow: lapTopCardNum ? lapTopCardNum : 3,
          afterChange: (current) => {
          showHideArrows(current, lapTopCardNum)
          },
        },
      },
    ],
    
  };
  return (
    <SliderContainer {...props}>
      {renderTop && renderTop()}
      <Slider {...settings}>{children}</Slider>
      {renderBottom && renderBottom()}
    </SliderContainer>
  );
};

export default ImageSlider;
