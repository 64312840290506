import { useEffect } from 'react';

export const useScrollListener = (selector, deps, onScroll) => {
  useEffect(() => {
    const el = selector ? document.querySelector(selector) : window;
    onScroll({ target: el });

    if (el) el.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      if (el) el.removeEventListener('scroll', onScroll);
    };
  }, deps);
};
