import React from 'react';
import styled from 'styled-components';

import Modal from '../Modal';
import {RalewayBold, RalewayRegular} from "../../components/Text";

import { Colors } from '../../constants/Colors';


import ConfirmIcon from '../../assets/img/email-sent.png';
import ConfirmIcon2x from '../../assets/img/email-sent@2x.png';
import ConfirmIcon3x from '../../assets/img/email-sent@3x.png';
import ConfirmIcon4x from '../../assets/img/email-sent@4x.png';
import CloseIcon from '../../assets/img/close.png';
import CloseIcon2x from '../../assets/img/close@2x.png';
import CloseIcon3x from '../../assets/img/close@3x.png';
import CloseIcon4x from '../../assets/img/close@4x.png';


const SentContainer = styled.div`
  padding: 31px 22px;

  div {
    img {
      width: 100%;
    }
  }

  h1 {
    ${RalewayBold}
    font-size: 14px;
    line-height: 159.4%;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: ${Colors.DARK};
    margin: 0;
  }

  p {
    ${RalewayRegular}
    font-size: 16px;
    line-height: 159.4%;
    color: ${Colors.GRAY};
    margin: 0;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 31px;
`;

const CloseIconContainer = styled.div`
  width: 12px;
  height: 12px;
  margin-top: -12px;

  cursor: pointer;
`;

const SentIconContainer = styled.div`
  width: 51px;  
`;

const SentSent = ({isOpen, onDismiss, ...props}) => {
  return ( 
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
        <SentContainer>
          <ModalHeader>
            <SentIconContainer>
               <img
                  alt="email-sent"
                  srcSet={`
                    ${ConfirmIcon4x} 4x,
                    ${ConfirmIcon3x} 3x,
                    ${ConfirmIcon2x} 2x,
                    ${ConfirmIcon} 1x`}
                  src={ConfirmIcon}
              />
            </SentIconContainer>
            <CloseIconContainer onClick={onDismiss}>
               <img
                  alt="close"
                  srcSet={`
                    ${CloseIcon4x} 4x,
                    ${CloseIcon3x} 3x,
                    ${CloseIcon2x} 2x,
                    ${CloseIcon} 1x`}
                  src={CloseIcon}
              />              
            </CloseIconContainer>
          </ModalHeader>
        <h1>Recebemos sua mensagem!</h1>
        <p>Logo, algum especialista da WeFit irá retornar sua mensagem. Obrigado pelo contato</p>
      </SentContainer>
    </Modal>
 );
}
 
export default SentSent;