import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';

// import { incrementOverlayCount, decrementOverlayCount } from '../../redux/src/overlay/actions';

const fadeIn = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
  }

  to {
    background-color: rgba(0, 0, 0, .6);
  }
`;

const fadeOut = keyframes`
  from {
    background-color: rgba(0, 0, 0, .6);
  }

  to {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .6);
  animation: ${({ isOpen }) => isOpen ? fadeIn : fadeOut} 0.4s;
`;


const Backdrop = ({ isOpen, ...props }) => {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(incrementOverlayCount());
  //   return () => {
  //     dispatch(decrementOverlayCount());
  //   };
  // }, []);
  return (
    <StyledBackdrop isOpen={isOpen} {...props} />
  );
};

export default styled(Backdrop)``;
