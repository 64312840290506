import React from "react";
import styled from "styled-components";

import LoadIcon from "./LoadIcon";

import { Colors } from "../constants/Colors";
import { RalewayBold } from "../components/Text";
import { mediaHelper } from "../utils/media-helper";

const ButtonContainer = styled.button`
  ${RalewayBold};
  background-color: ${({ transparent }) =>
    transparent ? "transparent" : Colors.YELLOW};
  color: ${Colors.DARK};
  width: ${({ buttonSize }) => buttonSize && `${buttonSize}px`};
  padding-top: 13px;
  padding-bottom: 13px;
  border: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 159.37%;
  padding-left: ${({ lateralPadding }) =>
    lateralPadding ? lateralPadding : "0px"};
  padding-right: ${({ lateralPadding }) =>
    lateralPadding ? lateralPadding : "0px"};
  cursor: pointer;

  &:hover {
    background-color: ${({ transparent }) =>
      transparent ? Colors.LIGHT_GRAY : Colors.DARK_YELLOW};
  }

  &:active {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${Colors.LIGHT_GRAY};
    color: ${Colors.GRAY};

    &:hover,
    &:active {
      background-color: ${Colors.LIGHT_GRAY};
      color: ${Colors.GRAY};
    }
  `}

  ${mediaHelper.md`   
    padding-top: 17px;
    padding-bottom: 17px;     
  `};

  &:focus {
    outline: none;
  }
`;

const Button = ({
  children,
  onLoading,
  onClick,
  lateralPadding,
  transparent,
  ...props
}) => {
  return (
    <ButtonContainer
      {...props}
      transparent={transparent}
      lateralPadding={lateralPadding}
      onClick={onClick}
    >
      {onLoading ? (
        <LoadIcon color={Colors.DARK} size={22} borderWeight={2} />
      ) : (
        children
      )}
    </ButtonContainer>
  );
};

export default Button;
