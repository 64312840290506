export const removeEmptyFields = (obj) =>
  Object.keys(obj)
    .filter((k) => obj[k] != null && obj[k] !== "") // Remove undef. and null.
    .reduce(
      (newObj, k) => {
        if (typeof obj[k] === "object") {
          return Array.isArray(newObj)
            ? [...newObj, removeEmptyFields(obj[k])]
            : { ...newObj, [k]: removeEmptyFields(obj[k]) };
        } else {
          return Array.isArray(newObj)
            ? [...newObj, obj[k]]
            : { ...newObj, [k]: obj[k] }; // Copy value
        }
      },
      Array.isArray(obj) ? [] : {}
    );
