class FieldHelper {
  static getValueFromFieldName(obj, fieldName) {
    const hasPoint = fieldName.indexOf('.') > -1;
    const hasBrackets = fieldName.indexOf('[') > -1 && fieldName.indexOf(']') > -1;

    if (!hasPoint && !hasBrackets) {
      return obj[fieldName];
    }

    let finalValue = obj;
    let fieldNameReplaced = (fieldName || '')
      .replace(']', '')
      .split('.');

    if (hasBrackets) {
      fieldNameReplaced = fieldNameReplaced.reduce((prevValue, currentValue) => [
        ...prevValue,
        ...currentValue.split('['),
      ], []);
    }

    fieldNameReplaced.forEach((pathPiece, index) => {
      if (finalValue && finalValue[pathPiece]) {
        finalValue = finalValue[pathPiece];
      } else if (fieldNameReplaced.length - 1 === index) {
        finalValue = null;
      }
    });

    return finalValue;
  }
}

export default FieldHelper;
