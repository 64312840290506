import React from "react";
import { Formik, Field } from "formik";
import styled from "styled-components";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import InputField from "../form/InputField";
import TextAreaField from "../form/TextAreaField";
import Button from "../Button";

import { Colors } from "../../constants/Colors";
import { mediaHelper } from "../../utils/media-helper";
import Mask from "../../utils/mask";
import { useWindowSize } from "../../helpers/window-resize";
import { BreakpointValues } from "../../constants/Breakpoints";

const FormContainer = styled.div`
  background: ${Colors.LIGHTEST_GRAY};
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.08);
  padding: 32px;

  ${mediaHelper.md`    
    width: 300px;
  `};

  ${mediaHelper.lg`    
    width: 459px;
  `};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    cursor: pointer;
  }
`;

const ContactForm = ({ onSubmit, emailSent, originPage, ...props }) => {
  const currentWindowSize = useWindowSize();
  const isDesktop = currentWindowSize.width >= BreakpointValues.md;

  return (
    <FormContainer>
      <Formik
        initialValues={{
          companyName: "",
          userName: "",
          userEmail: "",
          phoneNumber: "",
          message: "",
        }}
        onSubmit={(values, formikBag) => {
          onSubmit(values);
          formikBag.resetForm();
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <form>
              <Field
                name="companyName"
                disabled={emailSent.isLoading}
                placeholder="Nome da sua empresa"
                type="text"
                component={InputField}
              />
              <Field
                name="userName"
                disabled={emailSent.isLoading}
                placeholder="Seu nome"
                type="text"
                component={InputField}
              />
              <Field
                name="userEmail"
                disabled={emailSent.isLoading}
                placeholder="Seu e-mail"
                type="text"
                component={InputField}
              />
              <Field
                name="phoneNumber"
                disabled={emailSent.isLoading}
                placeholder="Seu telefone"
                type="text"
                format={Mask.tel}
                parse={Mask.tel}
                component={InputField}
              />
              <Field
                name="message"
                disabled={emailSent.isLoading}
                placeholder="Mensagem"
                type="text"
                component={TextAreaField}
              />
              <ButtonContainer>
                <Button
                  disabled={emailSent.isLoading || values.companyName.length === 0 || values.userName.length === 0 || values.userEmail.length === 0 || values.phoneNumber.length === 0 || values.message.length === 0}
                  buttonSize={256}
                  onClick={e => {
                    e.preventDefault();
                    trackCustomEvent({        
                      category: "Enviar mensagem",          
                      action: `btn-click`,          
                      label: `Enviar mensagem - ${originPage}`,          
                    });
                    handleSubmit();
                  }}
                  onLoading={emailSent.isLoading}
                  lateralPadding={isDesktop ? "68px" : "34px"}
                >
                  Enviar mensagem
                </Button>
              </ButtonContainer>
            </form>
          </>
        )}
      </Formik>
    </FormContainer>
  );
};

export default ContactForm;
