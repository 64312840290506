import { useEffect } from 'react';

/**
 * Handle clicks outside an element
 * @param {String} elName - element custom name
 * @param {Boolean} enableHandler - enable or disable this handler
 * @param {Function} onOutsideClick - callback to the outside click
 */
export const useOutsideClick = (elName, enableHandler, onOutsideClick) => {
  const _handleBodyClick = (e) => {
    const elementExists = e.target.closest('body');

    if (!elementExists || e.target.closest(`[data-name="${elName}"]`)) return;

    onOutsideClick();
  };

  useEffect(() => {
    if (enableHandler) {
      window.addEventListener('click', _handleBodyClick);
    } else {
      window.removeEventListener('click', _handleBodyClick);
    }
    return function cleanup() {
      window.removeEventListener('click', _handleBodyClick);
    };
  }, [_handleBodyClick, enableHandler]);
};
