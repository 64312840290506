import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RalewayBold } from '../Text';
import { Colors } from '../../constants/Colors';

const propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    error: PropTypes.string,
  }).isRequired,
  validationMinHeight: PropTypes.bool,
};

const defaultProps = {
  validationMinHeight: true,
};

const ValidationErrorContainer = styled.div`
  width: 100%;
  min-height: ${({ validationMinHeight }) => validationMinHeight ? '20px' : 'auto'};
  opacity: ${({ isVisible }) => isVisible ? '1' : '0'};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.DANGER};
`;

const ValidationErrorText = styled.span`
  font-size: 12px;
  white-space: nowrap;
  ${RalewayBold}
`;

// create a component
const ValidationError = ({
  meta,
  validationMinHeight,
  ...props
}) => (
  <ValidationErrorContainer
    isVisible={meta && meta.touched && meta.error}
    validationMinHeight={validationMinHeight}
    {...props}
  >
    {meta && meta.touched && meta.error && (
      <ValidationErrorText>
        {meta.error}
      </ValidationErrorText>
    )}
    {validationMinHeight}
  </ValidationErrorContainer>
);
ValidationError.propTypes = propTypes;
ValidationError.defaultProps = defaultProps;

export default ValidationError;
