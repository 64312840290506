  export const MethodologyContentType = {
    DIAGNOSIS: 1,
    MONITORING: 2,
    VALIDATION: 3,
    EVOLUTION: 4
  }

  export const GalleryArrowType = {
    WHITE: 1,
    GRAY: 2,
  }