import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants/Colors";
import Button from "../Button";

import { GalleryArrowType } from "../../constants/enums";
import { mediaHelper } from "../../utils/media-helper";

const GalleryArrow = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: transparent;
  bottom: 5px;
  transform: translateY(-50%);
  z-index: 100;
  opacity: ${({nextArrowActive, prevArrowActive}) => (nextArrowActive || prevArrowActive) ? 1 : 0};
  transition: 0.2s opacity ease;

  ${({ left }) => left && "left: 24%"};
  ${({ right }) => right && "right: 24%"};

  ${mediaHelper.lg`    
    ${({ left }) => left && "left: 43%"};
    ${({ right }) => right && "right: 43%"};
    bottom: ${({ arrowPos }) => arrowPos && arrowPos};
  `};

  &:hover {
    background-color: transparent;

    svg {
      path {
        fill: ${({ navColor }) =>
          navColor && navColor === GalleryArrowType.WHITE
            ? Colors.LOW_LIGHT_GRAY
            : Colors.DARK_LIGHT_GRAY};
      }
    }
  }

  &:active {
    box-shadow: none;

    svg {
      path {
        fill: ${({ navColor }) =>
          navColor && navColor === GalleryArrowType.WHITE
            ? Colors.DARK_LIGHT_GRAY
            : Colors.GRAY};
      }
    }
  }
`;

const getArrowColor = (navColor) => {
  if (navColor === GalleryArrowType.WHITE) {
    return "#FFFFFF";
  }

  if (navColor === GalleryArrowType.GRAY) {
    return Colors.LOW_LIGHT_GRAY;
  }

  return;
};

export const ArrowPrev = ({ fill }) => (
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.41083 7.25933L8.5 12.7272L6.71338 14.5L1.99258e-06 7.25933L6.71338 -8.95526e-07L8.5 1.77284L3.41083 7.25933Z"
      fill={fill}
    />
  </svg>
);

export const ArrowNext = ({ fill }) => (
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.58917 7.74067L0.5 2.27284L2.28662 0.5L9 7.74067L2.28662 15L0.499999 13.2272L5.58917 7.74067Z"
      fill={fill}
    />
  </svg>
);

export const NextArrow = (props) => {
  const { onClick, arrowPos, navColor, nextArrowActive } = props;

  return (
      <GalleryArrow
        onClick={onClick}
        right
        arrowPos={arrowPos}
        navColor={navColor}
        nextArrowActive={nextArrowActive}
      >
        <ArrowNext fill={getArrowColor(navColor)}></ArrowNext>
      </GalleryArrow>
  );
};

export const PrevArrow = (props) => {
  const { onClick, arrowPos, navColor, prevArrowActive } = props;

  return (
      <GalleryArrow
        onClick={onClick}
        left
        arrowPos={arrowPos}
        navColor={navColor}
        prevArrowActive={prevArrowActive}
      >
        <ArrowPrev fill={getArrowColor(navColor)}></ArrowPrev>
      </GalleryArrow>
  );
};
