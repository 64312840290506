import LogoAzul from "../assets/img/testimony/azul-dark-logo.png"
import LogoAzul2x from "../assets/img/testimony/azul-dark-logo@2x.png"
import LogoAzul3x from "../assets/img/testimony/azul-dark-logo@3x.png"
import LogoAzul4x from "../assets/img/testimony/azul-dark-logo@4x.png"

import LogoLuft from "../assets/img/testimony/luft-dark-logo.png"
import LogoLuft2x from "../assets/img/testimony/luft-dark-logo@2x.png"
import LogoLuft3x from "../assets/img/testimony/luft-dark-logo@3x.png"
import LogoLuft4x from "../assets/img/testimony/luft-dark-logo@4x.png"

import LogoRepom from "../assets/img/testimony/logo-repom.png"
import LogoRepom2x from "../assets/img/testimony/logo-repom@2x.png"
import LogoRepom3x from "../assets/img/testimony/logo-repom@3x.png"
import LogoRepom4x from "../assets/img/testimony/logo-repom@4x.png"


export const Testimony = [ 
  {    
    message: "A equipe da WeFit fez uma imersão para estudar o setor da aviação e entender as necessidades do meu cliente. Conduzem o processo com a área de negócio de maneira fluida, e, por fim, fazem a ligação perfeita com o mundo técnico. Atualmente os objetivos de negócio da Azul passaram a ser os objetivos da WeFit, e hoje confiamos neles para embasar a tomada de decisão como se fizessem parte do meu time interno de tecnologia.",
    author: "Felipe Starling",
    position: "Gerente Geral de TI",
    logo: LogoAzul,
    logo2x: LogoAzul2x,
    logo3x: LogoAzul3x,
    logo4x: LogoAzul4x,
  },
  {    
    message: "Não somos uma empresa de tecnologia mas dependemos da tecnologia para inovar e nos manter à frente do mercado. A WeFit traz o melhor do mundo das startups e metodologias de empresas como Google e Facebook para dentro da Luft. Isso nos permite inovar com agilidade.",
    author: "Gustavo Saraiva",
    position: "CIO",
    logo: LogoLuft,
    logo2x: LogoLuft2x,
    logo3x: LogoLuft3x,
    logo4x: LogoLuft4x,
  },
  {    
    message: "O principal impacto na Repom desde a chegada da Wefit foi que conseguimos olhar para a inovação. A Wefit nos ajudou a desenvolver nossa estratégia B2C, uma oportunidade que ainda não havíamos conseguido explorar, e está envolvida em todas as nossas ações voltadas ao consumidor final. O impacto disso para o negócio foi enorme.",
    author: "Rubens Naves",
    position: "CEO da Repom",
    logo: LogoRepom,
    logo2x: LogoRepom2x,
    logo3x: LogoRepom3x,
    logo4x: LogoRepom4x,
  },
]