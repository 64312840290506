import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import scrollTo from "gatsby-plugin-smoothscroll";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import ResponsiveMenu from "./ResponsiveMenu";
import Button from "../Button";
import {
  RalewayRegular,
  RalewaySemiBold,
} from "../Text";
// import PlatformLoginModal from "../PlatformLoginModal/PlatformLoginModal";
import Container from "../Container";

import { useWindowSize } from "../../helpers/window-resize";
import { useScrollListener } from "../../helpers/scroll-listener";
import { BreakpointValues } from "../../constants/Breakpoints";
import { Colors } from "../../constants/Colors";
import { mediaHelper } from "../../utils/media-helper";

import WefitLogo from "../../assets/img/wefit-logo.png";
import WefitLogo2x from "../../assets/img/wefit-logo@2x.png";
import WefitLogo3x from "../../assets/img/wefit-logo@3x.png";
import WefitLogo4x from "../../assets/img/wefit-logo@4x.png";

import WefitLogoDark from "../../assets/img/wefit-logo-dark.png";
import WefitLogoDark2x from "../../assets/img/wefit-logo-dark@2x.png";
import WefitLogoDark3x from "../../assets/img/wefit-logo-dark@3x.png";
import WefitLogoDark4x from "../../assets/img/wefit-logo-dark@4x.png";

import Hamburger from "../../assets/img/hamburger.png";
import Hamburger2x from "../../assets/img/hamburger@2x.png";
import Hamburger3x from "../../assets/img/hamburger@3x.png";
import Hamburger4x from "../../assets/img/hamburger@4x.png";

import HamburgerDark from "../../assets/img/hamburger-dark.png";
import HamburgerDark2x from "../../assets/img/hamburger-dark@2x.png";
import HamburgerDark3x from "../../assets/img/hamburger-dark@3x.png";
import HamburgerDark4x from "../../assets/img/hamburger-dark@4x.png";

const NavContainer = styled(Container)`
  padding-top: 0;
  padding-bottom: 0;
`;

const NavbarContainer = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;

  nav {
    &:first-of-type {
      flex: 1;
    }

    & > ul {
      display: flex;
      list-style-type: none;
      align-items: center;
      justify-content: flex-start;
      margin: 0;

      li {
        margin: 0 12px;

        a {
          padding: 12px 6px;
          ${RalewayRegular};
          text-transform: uppercase;
          font-size: 11px;
          text-decoration: none;

          ${mediaHelper.xl`
            font-size: 12px;
          `}
        }
      }
    }

    button {
      a {
        text-decoration: none;
        color: ${Colors.DARK};
        font-size: 11px;

        ${mediaHelper.xl`
          font-size: 12px;
        `}
      }
    }
  }
`;

const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  transition: 0.5s background-color ease;
  background-color: ${({ isScrollOnTop }) =>
    isScrollOnTop ? "transparent" : "white"};
  z-index: 100000;
  box-shadow: ${({ isScrollOnTop }) =>
    isScrollOnTop ? "none" : "0px 8px 12px rgba(0, 0, 0, 0.08)"};

    a {
        color: ${({ isScrollOnTop }) => (isScrollOnTop ? "white" : Colors.DARK)};

        &:hover {     
            color: ${({ isScrollOnTop }) => (isScrollOnTop ? Colors.LIGHT_GRAY : Colors.DARKER)};
        }
      }

  }
`;

const Logo = styled.img`
  width: 96px;
  height: 32px;
`;

const ResponsiveMenuContainer = styled.div`
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;

  h1 {
    ${RalewaySemiBold}
    font-size: 10px;
    text-transform: uppercase;
    color: white;
  }
`;

const NoStyleButton = styled(Button)`
  ${RalewayRegular}
  padding: 0;
  background: none;
  color: ${({ isScrollOnTop }) => (isScrollOnTop ? "white" : Colors.DARK)};
  text-transform: uppercase;
  font-size: 11px;
   
  ${mediaHelper.xl`
    font-size: 12px;
  `}

  &:hover,
  &:active {
    background: none;
    box-shadow: none;

    color: ${({ isScrollOnTop }) => (isScrollOnTop ? Colors.LIGHT_GRAY : Colors.DARKER)};
  }
`;

// const LoginLinkLi = styled.li`
//   position: relative;
// `;

const HamburgerContainer = styled.div`
  flex: 1;
  display: flex;

  div {
    width: 24px;
    img {
      width: 100%;
    }
  }

  h1 {
    transition: 0.2s color ease;
    color: ${({ isScrollOnTop }) => (isScrollOnTop ? "white" : Colors.DARK)};
  }
`;

const HEADER_COLLAPSE_SCROLL_LIMIT = 40;

const Header = ({ siteTitle, originPage }) => {
  const currentWindowSize = useWindowSize();
  const isDesktop = currentWindowSize.width >= BreakpointValues.lg;
  const [isScrollOnTop, setScrollOnTop] = useState(true);
  const [openResponsiveMenu, setOpenResponsiveMenu] = useState(false);

  // const [platformLogin, setPlatformLogin] = useState({
  //   isLoading: false,
  //   isModalOpen: false,
  // });

  // const RequestPlatformLogin = () => {
  //   setPlatformLogin({
  //     isLoading: false,
  //     isModalOpen: false,
  //   });
  // };

  useScrollListener(null, [], () => {
    setScrollOnTop(window.scrollY < HEADER_COLLAPSE_SCROLL_LIMIT);
  });

  return (
    <HeaderContainer isScrollOnTop={isScrollOnTop}>
      <NavContainer>
        {isDesktop ? (
          <NavbarContainer>
            <Link to="/">
              {isScrollOnTop ? (
                <img
                  alt="wefit-logo"
                  style={{
                    width: 96,
                    height: 32,
                  }}
                  srcSet={`
                ${WefitLogo4x} 4x,
                ${WefitLogo3x} 3x,
                ${WefitLogo2x} 2x,
                ${WefitLogo} 1x`}
                  src={WefitLogo}
                />
              ) : (
                <img
                  alt="wefit-logo-dark"
                  style={{
                    width: 96,
                    height: 32,
                  }}
                  srcSet={`
                ${WefitLogoDark4x} 4x,
                ${WefitLogoDark3x} 3x,
                ${WefitLogoDark2x} 2x,
                ${WefitLogoDark} 1x`}
                  src={WefitLogoDark}
                />
              )}
            </Link>
            <nav>
              <ul>
                <li>
                  <AnchorLink to="/#page-top">Início</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/#values">Quem somos</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/#methodology">Metodologia</AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/#contact" state={{ hash: "#contact" }}>
                    Fale Conosco
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink to="/#mission">Vagas</AnchorLink>
                </li>
              </ul>
            </nav>

            <nav>
              <ul>
                <li>                  
                    <NoStyleButton
                      onClick={e =>{
                        e.preventDefault();
                        trackCustomEvent({        
                          category: "Manifesto",          
                          action: "btn-click",          
                          label: "Manifesto - Header",
                        });
                        navigate('/manifest/');
                      }}
                      isScrollOnTop={isScrollOnTop}
                    >
                        Manifesto
                    </NoStyleButton>
                </li>
                {/* <LoginLinkLi> //HOLD
              <a onClick={() => setPlatformLogin({
                isLoading: false,
                isModalOpen: true,
              })}>Plataforma</a>
              <PlatformLoginModal platformLogin={platformLogin} isOpen={platformLogin.isModalOpen} onDismiss={() =>  setPlatformLogin({ isLoading: false, isModalOpen:false})} />
            </LoginLinkLi> */}
                <Button
                  onClick={e => {
                    e.preventDefault()
                      trackCustomEvent({        
                        category: "Marque uma conversa - Header",          
                        action: "btn-click",          
                        label: `Marque uma conversa - Header - ${originPage}`,          
                      })
                    scrollTo("#contact")}
                  }
                  lateralPadding={"15px"}
                >
                  <AnchorLink to="/#contact" state={{ hash: "#contact" }}>
                    Marque uma conversa
                  </AnchorLink>
                </Button>
              </ul>
            </nav>
          </NavbarContainer>
        ) : (
          <>
            <ResponsiveMenuContainer>
              <HamburgerContainer
                isScrollOnTop={isScrollOnTop}
                onClick={() => setOpenResponsiveMenu(true)}
              >
                <div>
                  {isScrollOnTop ? (
                    <img
                      alt="responsive-menu"
                      srcSet={`
                ${Hamburger4x} 4x,
                ${Hamburger3x} 3x,
                ${Hamburger2x} 2x,
                ${Hamburger} 1x`}
                      src={Hamburger}
                    />
                  ) : (
                    <img
                      alt="responsive-menu"
                      srcSet={`
                ${HamburgerDark4x} 4x,
                ${HamburgerDark3x} 3x,
                ${HamburgerDark2x} 2x,
                ${HamburgerDark} 1x`}
                      src={HamburgerDark}
                    />
                  )}
                </div>
                <h1>Menu</h1>
              </HamburgerContainer>
              <Link to="/">
                <Logo
                  alt=""
                  src={isScrollOnTop ? WefitLogo : WefitLogoDark}
                ></Logo>
              </Link>
            </ResponsiveMenuContainer>
            <ResponsiveMenu
              isScrollOnTop={isScrollOnTop}
              show={openResponsiveMenu}
              originPage={originPage}
              onDismiss={() => setOpenResponsiveMenu(false)}
            />
          </>
        )}
      </NavContainer>
    </HeaderContainer>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
