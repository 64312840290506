import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { trackCustomEvent, OutboundLink } from 'gatsby-plugin-google-analytics'

import LoadMask from "./LoadMask";
import Header from "./Header/header";
import Card from "./Card";
import {
  RalewayExtraBold,
  RalewayBold,
  RalewaySemiBold,
  RalewayRegular,
  RalewayItalic,
} from "../components/Text";
import { GalleryArrowType } from "../constants/enums";
import { Colors } from "../constants/Colors";
import { mediaHelper } from "../utils/media-helper";

import Button from "./Button";
import Container from "./Container";
import Slider from "./Slider/index.js";
import ContactForm from "./ContatctForm";
import ContainerWrapper from "./ContainerWrapper";

import BigQuote from "../assets/img/big-quote.png";
import BigQuote2x from "../assets/img/big-quote@2x.png";
import BigQuote3x from "../assets/img/big-quote@3x.png";
import BigQuote4x from "../assets/img/big-quote@4x.png";

import WhatsappIcon from "../assets/img/whatsapp.png";
import WhatsappIcon2x from "../assets/img/whatsapp@2x.png";

import LinkedInIcon from "../assets/img/linkedin.png";
import LinkedInIcon2x from "../assets/img/linkedin@2x.png";

import LogoArcelor from "../assets/img/clients/logo-arcelor.png";
import LogoAzul from "../assets/img/clients/logo-azul.png";
import LogoAzul2x from "../assets/img/clients/logo-azul@2x.png";
import LogoAzul3x from "../assets/img/clients/logo-azul@3x.png";
import LogoAzul4x from "../assets/img/clients/logo-azul@4x.png";
import LogoTudoAzul from "../assets/img/clients/logo-tudoazul.png";
import LogoTudoAzul2x from "../assets/img/clients/logo-tudoazul@2x.png";
import LogoTudoAzul3x from "../assets/img/clients/logo-tudoazul@3x.png";
import LogoTudoAzul4x from "../assets/img/clients/logo-tudoazul@4x.png";
import LogoAutopass from "../assets/img/clients/logo-autopass.png";
import LogoAutopass2x from "../assets/img/clients/logo-autopass@2x.png";
import LogoAutopass3x from "../assets/img/clients/logo-autopass@3x.png";
import LogoAutopass4x from "../assets/img/clients/logo-autopass@4x.png";
import LogoConnectCar from "../assets/img/clients/logo-connectcar.png";
import LogoConnectCar2x from "../assets/img/clients/logo-connectcar@2x.png";
import LogoConnectCar3x from "../assets/img/clients/logo-connectcar@3x.png";
import LogoConnectCar4x from "../assets/img/clients/logo-connectcar@4x.png";
import LogoLuft from "../assets/img/clients/logo-luft.png";
import LogoLuft2x from "../assets/img/clients/logo-luft@2x.png";
import LogoLuft3x from "../assets/img/clients/logo-luft@3x.png";
import LogoLuft4x from "../assets/img/clients/logo-luft@4x.png";
import LogoSantander from "../assets/img/clients/logo-santander.png";
import LogoSantander2x from "../assets/img/clients/logo-santander@2x.png";
import LogoSantander3x from "../assets/img/clients/logo-santander@3x.png";
import LogoSantander4x from "../assets/img/clients/logo-santander@4x.png";
import LogoSices from "../assets/img/clients/logo-sices.png";
import LogoSices2x from "../assets/img/clients/logo-sices@2x.png";
import LogoSices3x from "../assets/img/clients/logo-sices@3x.png";
import LogoSices4x from "../assets/img/clients/logo-sices@4x.png";
import WefitLogo from "../assets/img/wefit-logo.png"
import WefitLogo2x from "../assets/img/wefit-logo@2x.png"
import WefitLogo3x from "../assets/img/wefit-logo@3x.png"
import WefitLogo4x from "../assets/img/wefit-logo@4x.png"
import { Testimony } from "../dynamic-content/testimony";
import AlertSent from "./ContatctForm/AlertSent";

import { removeEmptyFields } from "../utils/remove-empty-fields";

const MasterContainer = styled.div`
  background-color: ${Colors.LIGHTEST_GRAY};
`;

export const SectionTitle = styled.h2`
    ${RalewayExtraBold}
    color: ${Colors.DARK};
    font-size: 24px;
    line-height: 40px;

  ${mediaHelper.md`        
    font-size: 32px;
    line-height: 48px;
  `};
`;

const ContactSection = styled(Container)`
  padding-top: 54px;

  ${mediaHelper.md`    
    display: flex;
    padding-bottom: 240px;    
  `};

  ul {
    padding-inline-start: 0;
  }

  p {
    ${RalewayRegular}
    color: ${Colors.GRAY};
    font-size: 16px;
    line-height: 25px;
  }

  h3 {
    ${RalewayBold}
    color: ${Colors.DARK};
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    margin-bottom: 24px;
    margin-top: 40px;      

    ${mediaHelper.md`   
      margin-top: 86px;      
    `};
  }
`;

const StyledLi = styled.li`
  margin-bottom: 24px;
  ${RalewayRegular}
  color: ${Colors.GRAY};
  font-size: 16px;
  line-height: 25px;

  &:before {
    content: "";
    background-color: ${Colors.DARK};
    font-weight: bold;
    display: inline-block; 
    width: 6px;
    height: 6px;
    margin-right: 10px;
    border-radius: 50%;
    margin-bottom: 2px;
  }
`;

const FormContainer = styled.div`
  ${mediaHelper.md`   
    margin-left: 40px;      
  `};
`;

const SocialmediaContainer = styled.div`
  display: flex;
  margin-top: 22px;
  align-items: center;

  & > a {
    width: 48px;
    height: 48px;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    background-repeat: no-repeat;

    &:first-of-type {
      background-size: 200%;
      background-position: 50% 0%;
      background-color: #2bb301;
      background-image: url(${WhatsappIcon});
      @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (   min--moz-device-pixel-ratio: 2),
        only screen and (     -o-min-device-pixel-ratio: 2/1),
        only screen and (        min-device-pixel-ratio: 2),
        only screen and (                min-resolution: 192dpi),
        only screen and (                min-resolution: 2dppx) { 
          background-image: url('${WhatsappIcon2x}');
        }
    }

    &:nth-of-type(2) {
      background-image: url(${LinkedInIcon});
      background-color: #0275b4;
      background-size: 70%;
      background-position: 50% 40%;
      margin-left: 14px;

      @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (   min--moz-device-pixel-ratio: 2),
        only screen and (     -o-min-device-pixel-ratio: 2/1),
        only screen and (        min-device-pixel-ratio: 2),
        only screen and (                min-resolution: 192dpi),
        only screen and (                min-resolution: 2dppx) { 
          background-image: url('${LinkedInIcon2x}');
        }
    }
  }

  p {
    flex: 1;
  }
`;

const HalfBackground = styled.div`
  height: 273px;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: ${Colors.LOW_LIGHT_GRAY_BACKGROUND};

  ${mediaHelper.sm`      
    height: 220px;
  `};

  ${mediaHelper.md`      
    height: 250px;
  `};
`;

const ClientsSection = styled.div`
  padding: 32px;
  position: relative;
  top: 0;
  background-color: ${Colors.DARK};
  margin-left: calc((100% - 325px) / 2);

  ${mediaHelper.es`
    margin-left: calc((100% - 435px) / 2);
  `};

  ${mediaHelper.sm`    
    margin-left: calc((100% - 540px) / 2);
    max-width: inherit;
    position: relative;

  `};

  ${mediaHelper.md`    
    margin-left: calc((100% - 720px) / 2);
    top: -100px;
  `};

  ${mediaHelper.lg`  
    margin-left: calc((100% - 960px) / 2);
  `};

  ${mediaHelper.xl`    
    margin-left: calc((100% - 1140px) / 2);
  `};

  ${mediaHelper.xxl`    
    margin-left: calc((100% - 1240px) / 2);
  `};

  && > h2 {
    ${RalewayBold}
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: white;
    margin: 0;
  }

  & > div {
    display: flex;

    ${mediaHelper.sm`   
      display: block;
    `};
  }
`;

const LogosRow = styled.div`
  display: block;

  ${mediaHelper.sm`
    display: flex;
    justify-content: space-between;
    margin: 22px 0;
  `};

  & > div {
    margin: 20px 0;

    ${mediaHelper.sm`    
      width: 150px;
    `};

    ${mediaHelper.md`    
      margin: 0;
    `};

    ${mediaHelper.lg`    
        width: 200px;
    `};

    & > img {
      width: 100%;
      opacity: 0.5;
    }
  }
`;

const TestimonySection = styled(Container)`
  position: relative;
  padding-top: 40px 0;

  ${mediaHelper.md`    
    padding-top: 0;
  `};

  h2 {
    margin-top: 0;
    margin-bottom: 90px;
    margin-left: 25px;
    margin-right: 25px;

    ${mediaHelper.md`    
      margin-left: 0;
      margin-right: 0;
    `};
  }
`;

const TestimonyCard = styled.div`
    background: ${Colors.LIGHTEST_GRAY};
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.08);
    padding: 25px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-left: 25px;

    ${mediaHelper.md`    
      margin-right: 40px;
      margin-left: 0;
    `};

    p {
      ${RalewayItalic}
      color: ${Colors.GRAY};
      font-size: 14px;
      line-height: 25px;
      text-align: justify;
      margin: 0;
      flex: 1;

      ${mediaHelper.md`          
        font-size: 16px;
      `};
    }
`;

const TestimonyCardFooter = styled.div`
  display: flex;

   h2 {
      ${RalewaySemiBold}
      color: ${Colors.DARK};
      font-size: 18px;
      line-height: 29px;
      margin: 0;
    }
    & > div:first-child {
      flex: 1;

      h3 {
        ${RalewaySemiBold}
        color: ${Colors.DARK};
        font-size: 14px;
        line-height: 22px;
        margin: 0;
      }
    }
`;

const TestimonyQuoteContainer = styled.div`
  position: absolute;
  z-index: 0;
  ${({ inverted }) =>
    inverted
      ? TestimonyQuotationInvertedMixin
      : `
    top: -63px;
    left: -20px;
  `};
`;

const TestimonyQuotationInvertedMixin = css`
  right: 0;
  bottom: 37px;
  transform: rotate(180deg);

  ${mediaHelper.sm`          
    right: -18px;    
  `};

  ${mediaHelper.md`          
    right: -18px;    
  `};

  ${mediaHelper.lg`
    bottom: 80px;    
  `};
`;

const MissionSection = styled(Container)`

  ${mediaHelper.md`          
    padding-top: 60px;
    padding-bottom: 70px;
    display: flex;      
  `};

  & > div {
    margin-bottom: 55px;

    ${mediaHelper.md`          
      margin-bottom: 0;
      margin-right: ${({ fullColumn }) => (fullColumn ? "0" : "130px")};
      width: ${({ fullColumn }) => (fullColumn ? "100%" : "50%")};
      


      &:nth-child(2) {
        margin-right: 0;
      }
    `};

    h2 {
      ${RalewayBold}
      color: ${Colors.DARK};
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.075em;
      text-transform: uppercase;
    }

    & > div {
      align-items: center;
      display: block;

      ${mediaHelper.lg`
        display: ${({ fullColumn }) => (fullColumn ? "flex" : "block")};
      `}
      p {
        ${RalewayRegular}
        color: ${Colors.GRAY};
        font-size: 16px;
        line-height: 25px;
      }
    }

    & a {
      width: ${({ fullColumn }) => (fullColumn ? "20%" : "initial")};
      text-decoration: none;
      color: ${Colors.DARK};
      margin-left: 0;

      ${mediaHelper.lg`
        margin-left: ${({ fullColumn }) => (fullColumn ? "5%" : "0")};           
      `}
    }
  }
`;

const Footer = styled(Container)`
  padding-top: 34px;
  padding-bottom: 34px;
  ${mediaHelper.md`        
    display: flex;    
  `};

  & > div {
    margin-bottom: 40px;
    ${mediaHelper.md`        
      margin-right: 60px;
      margin-bottom: 0;
    `};
    &:first-child {
      display: flex;
      align-items: center;
      margin-right: 0;
      flex: 1;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      height: 32px;
      width: 96px;
    }

    h3 {
      ${RalewayBold}
      color: white;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.075em;
      text-transform: uppercase;
    }

    p {
      ${RalewayRegular}
      color: white;
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

const Layout = ({ children, location, originPage,...props }) => {
  const PATH_NAME = {
    HOME: "/",
    MANIFEST: "/manifest/",
  };

  const pathname = location && location.pathname;

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [emailSent, setEmailSent] = useState({
    isLoading: false,
    isModalOpen: false,
  });
  const [isPageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("load", setPageLoading(false));
  });

  const RequestSendMessage = async (requestBody) => {
    setEmailSent({ isLoading: true, isModalOpen: false });

    try {
      const requestConfig = {
        method: "POST",
        body: requestBody && JSON.stringify(removeEmptyFields(requestBody)),
      };

      await fetch(
        "https://9qlpg5knh4.execute-api.us-east-1.amazonaws.com/prod/contact",
        requestConfig
      );
    } catch (error) {
      console.log(error);
    }

    setEmailSent({ isLoading: false, isModalOpen: true });
  };

  return (
    <>
      <MasterContainer>
        <Header originPage={originPage}  siteTitle={data.site.siteMetadata.title} />
        {children}
        <ContainerWrapper>
          <ContactSection id="contact">
            <div>
              <SectionTitle>Fale conosco</SectionTitle>
              <p>
                Queremos te ajudar a entender melhor seu consumidor
                <br />e como propor novas soluções para ele.
              </p>
              <h3>Na nossa conversa, vamos:</h3>
              <ul>
                <StyledLi>
                  Discutir sobre o seu negócio e suas necessidades
                </StyledLi>
                <StyledLi>Nossa metodologia estratégica e operacional</StyledLi>
                <StyledLi>
                  O que podemos fazer para impulsionar seu negócio através dos
                  dados dos seus consumidores
                </StyledLi>
              </ul>
            </div>
            <FormContainer>
              <ContactForm
                originPage={originPage}
                emailSent={emailSent}
                onSubmit={(values) => RequestSendMessage(values)}
              />
              <SocialmediaContainer>
                <p>Se preferir, converse conosco via:</p>
                <OutboundLink
                  href="https://api.whatsapp.com/send?phone=5511981871301&text=Olá%2C%20gostaria%20de%20mais%20informações?%20:)"
                  target="_blank"
                ></OutboundLink>
                <OutboundLink 
                  href="https://br.linkedin.com/company/wefit-tecnologia-estrat-gica"
                  target="_new"
                >
                </OutboundLink>
              </SocialmediaContainer>
            </FormContainer>
          </ContactSection>
        </ContainerWrapper>
        <ContainerWrapper style={{ position: "relative" }}>
          <HalfBackground />
          <ClientsSection>
            <h2>Clientes</h2>
            <div>
              <LogosRow>
                <div>
                  <img
                    alt="azul"
                    srcSet={`
                    ${LogoAzul4x} 4x,
                    ${LogoAzul3x} 3x,
                    ${LogoAzul2x} 2x,
                    ${LogoAzul} 1x`}
                    src={LogoAzul}
                  />
                </div>
                <div>
                  <img
                    alt="tudoAzul"
                    srcSet={`
                    ${LogoTudoAzul4x} 4x,
                    ${LogoTudoAzul3x} 3x,
                    ${LogoTudoAzul2x} 2x,
                    ${LogoTudoAzul} 1x`}
                    src={LogoTudoAzul}
                  />
                </div>
                <div>
                  <img
                    alt="arcelor"
                    // srcSet={`
                    // ${LogoArcelor4x} 4x,
                    // ${LogoArcelor3x} 3x,
                    // ${LogoArcelor2x} 2x,
                    // ${LogoArcelor} 1x`}
                    src={LogoArcelor}
                  />
                </div>
                <div>
                  <img
                    alt="sices"
                    srcSet={`
                    ${LogoSices4x} 4x,
                    ${LogoSices3x} 3x,
                    ${LogoSices2x} 2x,
                    ${LogoSices} 1x`}
                    src={LogoSices}
                  />
                </div>
              </LogosRow>
              <LogosRow>
                <div>
                  <img
                    alt="autopass"
                    srcSet={`
                    ${LogoAutopass4x} 4x,
                    ${LogoAutopass3x} 3x,
                    ${LogoAutopass2x} 2x,
                    ${LogoAutopass} 1x`}
                    src={LogoAutopass}
                  />
                </div>
                <div>
                  <img
                    alt="conectCar"
                    srcSet={`
                    ${LogoConnectCar4x} 4x,
                    ${LogoConnectCar3x} 3x,
                    ${LogoConnectCar2x} 2x,
                    ${LogoConnectCar} 1x`}
                    src={LogoConnectCar}
                  />
                </div>
                <div>
                  <img
                    alt="santander"
                    srcSet={`
                    ${LogoSantander4x} 4x,
                    ${LogoSantander3x} 3x,
                    ${LogoSantander2x} 2x,
                    ${LogoSantander} 1x`}
                    src={LogoSantander}
                  />
                </div>
                <div>
                  <img
                    alt="luft"
                    srcSet={`
                    ${LogoLuft4x} 4x,
                    ${LogoLuft3x} 3x,
                    ${LogoLuft2x} 2x,
                    ${LogoLuft} 1x`}
                    src={LogoLuft}
                  />
                </div>
              </LogosRow>
            </div>
          </ClientsSection>
        </ContainerWrapper>
        <ContainerWrapper backgroundColor={Colors.LOW_LIGHT_GRAY_BACKGROUND}>
          <TestimonySection>
            <SectionTitle>O que nossos clientes falam sobre nós?</SectionTitle>
            <Slider
              cardNum={1.92}
              itemsLength={Testimony.length}
              lapTopCardNum={1.92}
              tabletCardNum={1.2}
              mobileCardNum={1.1}
              arrowPos="-4px"
              navColor={GalleryArrowType.GRAY}
              renderTop={() => (
                <TestimonyQuoteContainer>
                  <img
                    atl="big-quote"
                     srcSet={`
                      ${BigQuote4x} 4x,
                      ${BigQuote3x} 3x,
                      ${BigQuote2x} 2x,
                      ${BigQuote} 1x`}
                    src={BigQuote}
                  />                  
                </TestimonyQuoteContainer>
              )}
              renderBottom={() => (
                <TestimonyQuoteContainer inverted>
                  <img
                    atl="big-quote"
                     srcSet={`
                      ${BigQuote4x} 4x,
                      ${BigQuote3x} 3x,
                      ${BigQuote2x} 2x,
                      ${BigQuote} 1x`}
                    src={BigQuote}
                  />                  
                </TestimonyQuoteContainer>
              )}
            >
              {Testimony.map((card, index) => (
                <Card
                  key={`${card.author}-index`}
                  noStyle
                  duration={600 * (index * 2)}
                >
                  <TestimonyCard>
                    <p>{card.message}</p>
                    <TestimonyCardFooter>
                      <div>
                        <h2>{card.author}</h2>
                        <h3>{card.position}</h3>
                      </div>
                      <div>
                        <img
                          atl="logo"
                           srcSet={`
                            ${card.logo4x} 4x,
                            ${card.logo3x} 3x,
                            ${card.logo2x} 2x,
                            ${card.logo} 1x`}
                          src={card.logo}
                        />
                      </div>
                    </TestimonyCardFooter>
                  </TestimonyCard>
                </Card>
              ))}
            </Slider>
          </TestimonySection>
        </ContainerWrapper>
        <ContainerWrapper>
          <MissionSection
            id="mission"
            fullColumn={pathname === PATH_NAME.MANIFEST}
          >
            {pathname === PATH_NAME.HOME ? (
              <div>
                <h2>Entenda a missão da wefit</h2>
                <div>
                  <p>
                    Saiba qual nosso propósito. Temos um grande desafio de
                    construir processos. Queremos inovar começando pelo cliente
                    e trabalhando de trás para frente.
                  </p>
                  <Button 
                    onClick={(e) => {
                       e.preventDefault();
                       trackCustomEvent({        
                         category: "Manifesto",          
                         action: "btn-click",          
                         label: "Manifesto - Final da página",          
                       });
                        navigate('/manifest/');
                     }
                    }   
                    lateralPadding="20px"
                    >
                    <div>
                        Acesse nosso manifesto
                    </div>
                  </Button>
                </div>
              </div>
            ) : null}
        
            <div fullColumn={pathname === PATH_NAME.MANIFEST}>
              <h2>Quer fazer parte do nosso time?</h2>
              <div>
                <p>
                  Se você é apaixonado por digital, dados ou desenvolvimento e
                  está a fim de encarar o desafio de quebrar paradigmas de outras
                  empresas,{" "}
                  <strong>
                    junte-se ao nosso time de designers e desenvolvedores.
                  </strong>
                </p>
                <OutboundLink 
                  href="https://br.linkedin.com/company/wefit-tecnologia-estrat-gica"
                  target="_new"
                >
                  <Button 
                    // onClick={(e) => {
                    //    e.preventDefault();
                    //    trackCustomEvent({        
                    //      category: "Vagas abertas",          
                    //      action: "btn-click",          
                    //      label: `Vagas abertas - ${originPage}`,          
                    //    });
                    //  }
                    // }   
                    lateralPadding="20px"
                    >
                    <div>
                        Ver vagas abertas
                    </div>
                  </Button>
                </OutboundLink>
              </div>
            </div>
          </MissionSection>
        </ContainerWrapper>
        <ContainerWrapper backgroundColor={Colors.GRAY}>
          <Footer>
            <div>
              <img
                alt="wefit-logo"
                srcSet={`
                  ${WefitLogo4x} 4x,
                  ${WefitLogo3x} 3x,
                  ${WefitLogo2x} 2x,
                  ${WefitLogo} 1x`}
                src={WefitLogo}  
              />
            </div>
            <div>
              <h3>Endereço</h3>
              <p>
                Av. Brig. Faria Lima, 1853, 1º Andar - Jardim Paulistano São
                Paulo, SP - Brasil
              </p>
            </div>
            <div>
              <h3>E-mail</h3>
              <p>contato@wefit.com.br</p>
            </div>
            <div>
              <h3>Telefone</h3>
              <p>+55 11 2738-1400</p>
            </div>
          </Footer>
        </ContainerWrapper>
        <AlertSent
          isOpen={emailSent.isModalOpen}
          onDismiss={() => setEmailSent(false)}
        />
      </MasterContainer>
      {isPageLoading && <LoadMask></LoadMask>}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
