class Mask {
  /**
   * Mask a string with only numbers
   * @param {*} text Ex: 89d8g8ffgdf9f575jhj4h6gfhh8
   * @return {string} Ex: 89889575468
   */
  static numbers(text) {
    return (text ? text.toString() : "").replace(/[^0-9]/g, "");
  }

  /**
   * Mask a string based on a Telephone format
   * @param {*} text Ex: 00000000000
   * @return {string} Ex: (00) 00000-0000
   */
  static tel(value) {
    const mask = [];
    const text = Mask.numbers(value);

    mask.push(
      ...["(", /[0-9]/, /[0-9]/, ")", " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    );

    if (text && text.length === 11) {
      mask.push(/[0-9]/);
    }

    mask.push(...["-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]);

    return Mask.custom(text, mask);
  }

  /**
   * Mask a string based on a regex pattern and strings
   * @param {string} text cleaned string to be masked
   * @param {string[] | RegExp[]} mask string or regex array to replace the text
   * @return {string}
   */
  static custom = (text, mask) => {
    const maskedText = [];
    let lastText = 0;
    let lastMask = 0;

    if (text) {
      while (
        lastText < text.length
        || (text.length > mask.length && lastMask < mask.length)
      ) {
        if (typeof mask[lastMask] === 'string') {
          maskedText.push(mask[lastMask]);

          lastMask += 1;
        } else if (
          typeof text[lastText] === 'string'
          && mask[lastMask] instanceof RegExp
          && mask[lastMask].test(text[lastText])
        ) {
          maskedText.push(text[lastText]);

          lastText += 1;
          lastMask += 1;
        } else {
          lastText += 1;
          lastMask += 1;
        }
      }
    }

    return maskedText.join('');
  };
}

export default Mask;
