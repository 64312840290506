import React, { Component } from "react";
import styled, {css} from "styled-components";
import AOS from "aos";

import { Watch } from "scrollmonitor-react";

import { Colors } from "../constants/Colors";

import 'aos/dist/aos.css'

const NoStyleMixin = css`
  background: transparent;
  box-shadow: none;
`;

const CardContainer = styled.div`
  background: ${Colors.LIGHTEST_GRAY};
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.08);

  &:focus {
    outline:0;
  }

  ${({noStyle}) => noStyle && NoStyleMixin}
`;


class Card extends Component {
  componentDidMount() {
    this.aos = AOS;
    this.aos.init();
  }

  componentDidUpdate() {
    this.aos = AOS;
    this.aos.refresh();
  }

  render() {
    const { children, noStyle, duration, dataAos } = this.props;

    return (
      <CardContainer
        noStyle={noStyle}
        className="aos-init aos-animate"
        data-aos={dataAos ? dataAos : "fade-up"}
        data-aos-duration={duration}
        {...this.props}>
        {children}
      </CardContainer>
    );
  }
}

export default Watch(Card);
