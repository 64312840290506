export const Alpha = (color = '#000000', alphaNumber = 0) => {
  if (
    typeof color === 'string'
    && color.length === 7
    && typeof alphaNumber === 'number'
    && (alphaNumber >= 0 || alphaNumber <= 1)
  ) {
    const hexArr = [
      color.substr(1, 2),
      color.substr(3, 2),
      color.substr(5, 2),
    ];
    const alphaArr = [
      parseInt(hexArr[0], 16),
      parseInt(hexArr[1], 16),
      parseInt(hexArr[2], 16),
    ];

    return `rgba(${alphaArr.join(',')}, ${alphaNumber})`;
  }

  return 'transparent';
};

export const Colors = {
  YELLOW: '#F5C400',
  DARK_YELLOW: '#E6B804',
  DARK: '#5C210B',
  DARKER: '#422000',
  GRAY: '#606060',
  LOW_LIGHT_GRAY: '#C0C0C0',
  LOW_LIGHT_GRAY_BACKGROUND: Alpha('#C0C0C0', 0.2),
  DARK_LIGHT_GRAY: '#9C9C9C',
  LIGHT_GRAY: '#E5E5E5',
  LIGHTEST_GRAY: '#F8F8F8',
};
