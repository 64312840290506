import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

import FieldHelper from '../../utils/forms/field-helper';
import { Colors } from '../../constants/Colors';
import ValidationError from './ValidationError';
import Spinner, { SpinnerSize } from '../Spinner';
import { RalewaySemiBold } from '../Text';
import Button from '../Button';

export const InputSize = {
  SMALL: 1,
  NORMAL: 2,
};

const propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  leftRender: PropTypes.func,
  rightRender: PropTypes.func,
  isLoading: PropTypes.bool,
  format: PropTypes.func,
  parse: PropTypes.func,
  field: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  autoselectOnFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onPressEnter: PropTypes.func,
  fieldProps: PropTypes.any,
  smallInput: PropTypes.bool,
  readOnly: PropTypes.bool,
  inline: PropTypes.bool,
  inverseBlue: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(InputSize)),
};

const defaultProps = {
  type: 'text',
  placeholder: '',
  leftRender: null,
  rightRender: null,
  format: value => value,
  parse: value => value,
  isLoading: false,
  isRequired: false,
  tabIndex: 0,
  autoselectOnFocus: false,
  onChange: value => value,
  onBlur: value => value,
  onFocus: value => value,
  onPressEnter: value => value,
  fieldProps: {},
  smallInput: false,
  readOnly: false,
  inline: false,
  inverseBlue: false,
  size: InputSize.NORMAL,
};

// Mixins
const errorInputMixin = css`
  border: 1px solid ${Colors.DANGER};
  
  &:focus {
    border: 1px solid ${Colors.DANGER};
  }
`;


const smallInputMixin = css`
  height: 32px;
`;

// Components

export const InputContainer = styled.div`
  border: 1px solid ${Colors.LOW_LIGHT_GRAY};
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: 2px solid transparent;

  ${({ disabled }) => disabled && `
    background-color: ${Colors.GRAY};
  `}
  
  ${({ isFocused }) => isFocused && css`
    border-color: ${Colors.YELLOW};
  `}

  ${({ inline }) => inline && css`
    min-width: 115px;
    width: 80%;
  `}
  
  ${({ meta }) => meta && meta.touched && meta.error ? errorInputMixin : ''}
`;


export const Input = styled.input`
  width: ${({ smallInput }) => smallInput ? '40px' : '100%'};
  min-width: 0;
  height: 40px;
  padding: 8px;
  border: 0;
  background: white;
  appearance: none;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.GRAY};
  outline: none;
  box-sizing: border-box;
  text-align: ${({ alignCenter }) => alignCenter ? 'center' : 'initial'};
  ${RalewaySemiBold}

  ::placeholder {
    color: ${Colors.DARK_LIGHT_GRAY};
  }

  &[disabled] {
    background-color: ${Colors.LIGHT_GRAY};
    color: ${Colors.GRAY}
  }

  ${({ size }) => size && _getInputSizeMixin(size)}
`;

const InputSpinner = styled(Spinner)`
  margin: 0 12px;
`;

const Wrapper = styled.div`
  ${({ inline }) => inline && css`
    display: flex;
  `}

  ${({ disabled, inverse }) => (disabled && inverse) && css`
    opacity: 0.3;
  `}
`;

const FieldValueText = styled.span`
  font-size:14px;
  color: ${Colors.DARK};
`;

const _getInputSizeMixin = (size) => {
  if (size === InputSize.SMALL) {
    return smallInputMixin;
  }

  return '';
};



const InputField = ({
  type,
  placeholder,
  disabled,
  readOnly,
  leftRender,
  rightRender,
  isLoading,
  field,
  format,
  parse,
  size,
  fieldProps,
  isRequired,
  tabIndex,
  classname,
  form: {
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  },
  inputProps,
  validationMinHeight,
  smallInput,
  alignCenter,
  inline,
  inverseBlue,
  isEditMode,
  autoselectOnFocus,
  onInputRef,
  onChange,
  onBlur,
  onFocus,
  onPressEnter,
  ...props
}) => {
  const inputRef = useRef(null);
  const selectionStart = useRef(null);
  const autoSelectionRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current.selectionStart && (selectionStart.current || selectionStart.current === 0)) {
      inputRef.current.selectionStart = selectionStart.current;
      inputRef.current.selectionEnd = selectionStart.current;
    }
  }, []);
  const meta = {
    touched: FieldHelper.getValueFromFieldName(touched, field.name),
    error: FieldHelper.getValueFromFieldName(errors, field.name),
    isFocused,
  };

  const _onFocus = () => {
    setIsFocused(true);
  };

  const _onBlur = () => {
    setIsFocused(false);
  };

  return (
    <Wrapper
      inline={inline}
      disabled={disabled}
      inverse={inverseBlue}
      {...props}
    >    
          <InputContainer
            meta={meta}
            isFocused={meta.isFocused}
            inline={inline}
            inverseBlue={inverseBlue}
            disabled={disabled}
          >                     
            {leftRender && leftRender()}
            <Input
              ref={(ref) => {
                inputRef.current = ref;
                if (onInputRef) onInputRef(ref);
              }}
              size={size}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              tabIndex={tabIndex}
              {...field}
              onKeyDown={(e) => {
                if (e.key.toLowerCase() === 'enter') {
                  onPressEnter(field.value);
                }
              }}
              onFocus={() => {
                clearImmediate(autoSelectionRef.current);
                onFocus(field.value);
                _onFocus();
                if (autoselectOnFocus) {
                  autoSelectionRef.current = setImmediate(() => {
                    inputRef.current.select();
                  });
                }
              }}
              onBlur={() => {
                clearImmediate(autoSelectionRef.current);
                onBlur(field.value);
                _onBlur();
                if (!meta.touched) {
                  setFieldTouched(field.name, true);
                }
              }}
              value={field.value !== undefined && field.value !== null ? format(field.value) : ''}
              smallInput={smallInput}
              inverseBlue={inverseBlue}
              alignCenter={alignCenter}
              onChange={(e) => {
                let currentSelection = e.target.selectionStart;
                const event = { ...e };
                const origValue = e.target.value;
                const maskedPrevValue = format(field.value) || '';
                // Note - we call format function to clear the input value
                // to remove exceeded characters
                event.target.value = parse(format(origValue));
                const maskedValue = format(event.target.value);
                const diffCaretPosistion = maskedValue.length - maskedPrevValue.length;
                currentSelection += (diffCaretPosistion > 1 ? diffCaretPosistion : 0);
                // Temporary comment because it doesn't changes the value based on the mask
                // field.onChange(event);
                setFieldValue(field.name, parse(format(origValue)));
                onChange(event.target.value);
                selectionStart.current = currentSelection;
              }}
              {...fieldProps}
            />
            {rightRender && rightRender()}
            {isLoading && (
              <InputSpinner
                size={SpinnerSize.SMALL}
              />
            )}
          </InputContainer>        
      {!inline && (
          <ValidationError
            meta={meta}
            validationMinHeight={validationMinHeight}
          />
        )}
    </Wrapper>
  );
};

InputField.propTypes = propTypes;
InputField.defaultProps = defaultProps;

export default InputField;
