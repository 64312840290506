import { useState, useEffect } from 'react';

// export const getWindowSize = () => {
//   return {
//     height: window.innerHeight,
//     width: window.innerWidth,
//   };
// }

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
      height: 0,
      width: 0,
    });
  function handleResize() {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      handleResize();
    }


    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return windowSize;
};
