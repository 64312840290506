import React , { useEffect, useState, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Link, navigate } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import scrollTo from 'gatsby-plugin-smoothscroll';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Button from "../Button";
import Backdrop from "../Backdrop";
import { Colors } from "../../constants/Colors";
import {
  RalewayRegular,
} from "../Text"

import { useOutsideClick } from "../../helpers/outside-click";
import {mediaHelper} from "../../utils/media-helper";

import WefitLogoDark from "../../assets/img/wefit-logo-dark.png"
import WefitLogoDark2x from "../../assets/img/wefit-logo-dark@2x.png"
import WefitLogoDark3x from "../../assets/img/wefit-logo-dark@3x.png"
import WefitLogoDark4x from "../../assets/img/wefit-logo-dark@4x.png"
import CloseIcon from '../../assets/img/close.png';
import CloseIcon2x from '../../assets/img/close@2x.png';
import CloseIcon3x from '../../assets/img/close@3x.png';
import CloseIcon4x from '../../assets/img/close@4x.png';


const fadeInItems = keyframes`
  from {
    left: -360px;
  }

  to {
    left: 0;  
  }
`;

const fadeOutItems = keyframes`
  from {
    left: 0;
  }

  to {
    left: -360px;
  }
`;

const fadeInItemsMobile = keyframes`
  from {
    left: -360px;
  }

  to {
    left: 0;  
  }
`;

const fadeOutItemsMobile = keyframes`
  from {
    left: 0;
  }

  to {
    left: -360px;
  }
`;

const ResponsiveMenuContainer = styled.div`  
  display: flex;
  flex-direction: column;
  opacity: 1;
  box-shadow: 0px 10px 7px rgba(0, 0, 0, 0.4);
`;

const MenuItemsColumn = styled.div`
  padding: 32px 24px;
  overflow: auto;
  position: fixed;
  top: 0;
  opacity: 1;
  left: -360px;
  background-color: white;
  z-index: 110;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  
  & nav > ul {
    display: flex;
    list-style-type: none;  
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    padding-left: 0;
    margin-top: 24px;

    li {      
      margin: 12px 0;
        a {
          ${RalewayRegular};
          text-transform: uppercase;
          font-size: 12px;
          text-decoration: none;
          color: ${Colors.DARK};
        }
      }
    }

    button {
      a {
        text-decoration: none;
        color: ${Colors.DARK};
      }
    }

  ${({ show }) => show && `
    left: 0;   
  `}

  animation: ${({ show }) => show ? css`${fadeInItemsMobile} 1s` : css`${fadeOutItemsMobile} 0.5s`};

  ${mediaHelper.sm`
    ${({ show }) => show && `
      left: 0;
    `}
    animation: ${({ show }) => show ? css`${fadeInItems} 1s` : css`${fadeOutItems} 0.5s`};
  `}
`;

const CloseIconContainer = styled.div`
  width: 12px;
  height: 12px;
  margin-top: -12px;

  cursor: pointer;

  img {
    width: 100%;
  }
`;

const ResponsiveMenuHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  a {
    flex: 1;
  }
`;

const ResponsiveMenu = ({
  onDismiss,
  show,
  originPage,
  ...props
}) => {
  const [shouldShow, setShouldShow] = useState(show);
  const setTimeoutRef = useRef(null);

  useEffect(() => {
    clearTimeout(setTimeoutRef.current);

    if (show) {
      setShouldShow(true);
    } else {
      requestAnimationFrame(() => {
        setTimeoutRef.current = setTimeout(() => {
          setShouldShow(false);
        }, 300);
      });
    }
  }, [show]);

  const MENU_ELEMENT_NAME = 'sc-menu';
  useOutsideClick(MENU_ELEMENT_NAME, shouldShow, onDismiss);

  return shouldShow ? ( 
    <>
       <ResponsiveMenuContainer
        data-name={MENU_ELEMENT_NAME}
        show={show}
        {...props}
      >
        <MenuItemsColumn show={show}>
          <ResponsiveMenuHeader>
            <Link to="/">
              <img
                style={{
                  width: 96,
                  height: 32,
                }}
                alt=""
                 srcSet={`
                    ${WefitLogoDark4x} 4x,
                    ${WefitLogoDark3x} 3x,
                    ${WefitLogoDark2x} 2x,
                    ${WefitLogoDark} 1x`}
                src={WefitLogoDark} 
              />
            </Link> 
            <CloseIconContainer onClick={onDismiss}>
               <img
                  alt="close"
                  srcSet={`
                    ${CloseIcon4x} 4x,
                    ${CloseIcon3x} 3x,
                    ${CloseIcon2x} 2x,
                    ${CloseIcon} 1x`}
                  src={CloseIcon}
              />              
            </CloseIconContainer>
          </ResponsiveMenuHeader>
          <nav>
            <ul>
              <li onClick={onDismiss}><AnchorLink to='/#page-top'>Início</AnchorLink></li>
              <li onClick={onDismiss}><AnchorLink to='/#values'>Quem somos</AnchorLink></li>
              <li onClick={onDismiss}>
                 <AnchorLink to="/#methodology">
                  Metodologia
                </AnchorLink>
              </li>
              <li onClick={onDismiss}><AnchorLink to="/#contact" state={{hash: "#contact"}}>Fale Conosco</AnchorLink></li>
              <li onClick={onDismiss}><AnchorLink to="/#mission" >Vagas</AnchorLink></li>
            </ul>
          </nav>
          

          <nav>
            <ul>
              <li>
                <Link 
                  onClick={e =>{
                    e.preventDefault();
                    trackCustomEvent({        
                      category: "Manifesto",          
                      action: "btn-click",          
                      label: "Manifesto - Header",
                    });
                    navigate('/manifest/');
                  }}
                >
                  Manifesto
                </Link>
              </li>
              {/* <LoginLinkLi> //HOLD
                <a onClick={() => setPlatformLogin({
                  isLoading: false,
                  isModalOpen: true,
                })}>Plataforma</a>
                <PlatformLoginModal platformLogin={platformLogin} isOpen={platformLogin.isModalOpen} onDismiss={() =>  setPlatformLogin({ isLoading: false, isModalOpen:false})} />
              </LoginLinkLi> */}
              <Button
                onClick={e => {
                  e.preventDefault();
                  trackCustomEvent({        
                    category: "Marque-uma-conversa - Header",          
                    action: "btn-click",          
                    label: `Marque uma conversa - Header - ${originPage}`,          
                  });
                  scrollTo("#contact");                
                  onDismiss();
                }}
                lateralPadding={"15px"}
              >
                  <AnchorLink to="/#contact" state={{hash: "#contact"}}>Marque uma conversa</AnchorLink>
              </Button>
            </ul>
          </nav>
        </MenuItemsColumn>
    </ResponsiveMenuContainer>
    <Backdrop isOpen={show} />
    </>

 ) : null;
}
 
export default ResponsiveMenu;