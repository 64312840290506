import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';


import Backdrop from './Backdrop';
import { useOutsideClick } from '../helpers/outside-click';
import { Colors } from '../constants/Colors';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  disableOutsideClick: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  blueHeader: PropTypes.bool,
};

const defaultProps = {
  blueHeader: false,
  disableOutsideClick: false,
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  overflow-y: auto;
  overflow-x: hidden;
`;

const fullScreenMixin = css`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-y: auto;
  border-radius: 0;
`;


const StyledModal = styled.dialog`
  background: ${Colors.LIGHTEST_GRAY};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  position: relative;
  width: calc(100% - 32px);
  border-radius: 16px;
  text-align: left;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0;
  padding: 0;
  left: auto;
  animation: ${({ open }) => open ? fadeIn : fadeOut} 0.4s;
  max-width: ${({ modalSize }) => modalSize ? modalSize : '500px'};
  background-color: ${({ blueModal }) => blueModal ? Colors.SECONDARY_LIGHT_BLUE : 'white'};

  ${({ fullScreen }) => fullScreen ? fullScreenMixin : ''}
`;

const MODAL_ELEMENT_NAME = 'sc-modal';

const Modal = ({
  dispatch,
  isOpen,
  disableOutsideClick,
  onDismiss,
  children,
  blueHeader,
  blueModal,
  fullScreen,
  modalSize,
  ...props
}) => {
  const [isActive, setIsActive] = useState(isOpen);
  const setTimeoutRef = useRef(null);

  useOutsideClick(
    MODAL_ELEMENT_NAME,
    isOpen,
    disableOutsideClick ? () => { } : onDismiss,
  );

  useEffect(() => {
    clearTimeout(setTimeoutRef.current);

    if (isOpen) {
      setIsActive(true);
    } else {
      if (!isActive) return;

      requestAnimationFrame(() => {
        setTimeoutRef.current = setTimeout(() => {
          setIsActive(false);
        }, 300);
      });
    }
  }, [isOpen]);

  return (
    isActive ? (
      <Wrapper>
        <StyledModal
          modalSize={modalSize}
          blueHeader={blueHeader}
          fullScreen={fullScreen}
          blueModal={blueModal}
          data-name={MODAL_ELEMENT_NAME}
          open={isOpen}
          {...props}
        >
          {children}
        </StyledModal>
        <Backdrop isOpen={isOpen} />
      </Wrapper>
    )
      : null
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;


export default Modal;
