import { css } from 'styled-components';
import './fonts/stylesheet.css';

export const FontWeight = {
  ExtraLight: '200',
  Light: '300',
  Regular: '400',
  Medium: '500',
  SemiBold: '600',
  Bold: '700',
  ExtraBold: '800',
  Black: '900',
  None: 'none',
};

export const RalewayBlack = css`
  font-family: 'Raleway';
  font-weight: 900;
  font-style: normal;
`;

export const RalewayExtraBold = css`
  font-family: 'Raleway';
  font-weight: 800;
  font-style: normal;
`;

export const RalewayBold = css`
  font-family: 'Raleway';
  font-weight: bold;
  font-style: normal;
`;

export const RalewaySemiBold = css`
  font-family: 'Raleway';
  font-weight: 600;
  font-style: normal;
`;

export const RalewayRegular = css`
  font-family: 'Raleway';
  font-weight: normal;
  font-style: normal;
`;

export const RalewayItalic = css`
  font-family: 'Raleway';
  font-weight: normal;
  font-style: italic;
`;


 