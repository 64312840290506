import React from 'react';
import styled from 'styled-components';

import {mediaHelper} from "../utils/media-helper"


const StyledContainer = styled.div`
  padding: 25px;
  margin-left: auto;
  margin-right: auto;

  ${mediaHelper.sm`
    padding: 0;    
    max-width: 540px;    
  `}


  ${mediaHelper.md`
    max-width: 720px;
  `}

  ${mediaHelper.lg`   
    max-width: 960px;
  `};

  ${mediaHelper.xl`    
    max-width: 1140px;    
  `};

  ${mediaHelper.xxl`    
    max-width: 1240px;    
  `};
`;

const Container = ({children, transparent, ...props}) => {

return ( 
    <StyledContainer transparent={transparent} {...props}>
      {children}
    </StyledContainer>
 );
}
 
export default Container;